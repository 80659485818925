import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CurrentUserService } from '@asecrmc/common/core/services/current-user.service';
import { routePaths } from '@asecrmc/common/core/utils/route-paths';

/**
 * Guard prevents a current user from accessing a route in case they are authorized.
 * @param _ Activated route snapshot.
 * @param state Router state snapshot.
 */
export function authorizedGuard(
	_: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
): Observable<boolean | UrlTree> {

	const currentUserService = inject(CurrentUserService);

	const router = inject(Router);

	return currentUserService.isAuthenticated$.pipe(
		map(isAuthenticated =>
			!isAuthenticated ?
				router.createUrlTree(routePaths.login, {
					queryParams: state.url ? { next: state.url } : undefined,
				}) :
				true),
	);
}
