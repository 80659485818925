import { Routes } from '@angular/router';
import { authorizedGuard } from '@asecrmc/common/core/guards/authorized.guard';
import { unauthorizedGuard } from '@asecrmc/common/core/guards/unauthorized.guard';

import { LayoutComponent } from './features/shared/layout/layout.component';

/** App routes. */
export const appRoutes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		canActivate: [authorizedGuard],
		children: [
			{
				path: 'customers',
				loadChildren: () => import('./features/customers/customers.routes').then(r => r.customerRoutes),
			},
			{
				path: 'devices',
				loadChildren: () => import('./features/devices/devices.routes').then(r => r.devicesRoutes),
			},
			{
				path: 'buildings',
				loadChildren: () => import('./features/buildings/buildings.routes').then(r => r.buildingsRoutes),
			},
			{
				path: 'proposals',
				loadChildren: () => import('./features/proposals/proposals.routes').then(r => r.proposalsRoutes),
			},
			{
				path: 'invoices',
				loadChildren: () => import('./features/invoices/invoices.routes').then(r => r.invoicesRoutes),
			},
			{
				path: 'inspections',
				loadChildren: () => import('./features/inspections/inspections.routes').then(r => r.inspectionsRoutes),
			},
			{
				path: 'periodic',
				loadChildren: () => import('./features/periodic/periodic.routes').then(r => r.periodicRoutes),
			},
			{
				path: 'schedules',
				loadChildren: () => import('./features/schedules/schedules.routes').then(r => r.schedulesRoutes),
			},
			{
				path: 'dob-now',
				loadChildren: () => import('./features/dob-now/dob-now.routes').then(r => r.dobNowRoutes),
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./features/next-years/next-years.routes').then(r => r.nextYearsRoutes),
			},
			{
				path: 'contacts',
				loadChildren: () => import('./features/contacts/contacts.routes').then(r => r.contactsRoutes),
			},
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
		],
	},
	{
		path: 'auth',
		canActivate: [unauthorizedGuard],
		loadChildren: () => import('./features/auth/auth.routes').then(r => r.authRoutes),
	},
	{ path: '**', redirectTo: 'dashboard' },
];
