<mat-toolbar
	color="primary"
	class="header"
>
	<a
		class="logo"
		[routerLink]="routePaths.home"
	>
		<img
			class="logo__image"
			src="assets/images/logo-white.png"
			alt="AllStar Elevator"
		/>
	</a>

	<nav class="header__nav list">
		<a
			class="list__item list-item"
			mat-button
			routerLinkActive="active"
			[routerLink]="routePaths.dashboard"
		>
			Dashboard
		</a>
		<a
			class="list__item list-item"
			mat-button
			routerLinkActive="active"
			[routerLink]="routePaths.customers"
		>
			Customers
		</a>
		<a
			class="list__item list-item"
			mat-button
			routerLinkActive="active"
			[routerLink]="routePaths.devices"
		>
			Devices
		</a>
		<a
			class="list__item list-item"
			mat-button
			routerLinkActive="active"
			[routerLink]="routePaths.buildings"
		>
			Buildings
		</a>
		<a
			class="list__item list-item"
			mat-button
			routerLinkActive="active"
			[routerLink]="routePaths.proposals"
		>
			Proposals
		</a>
		<a
			class="list__item list-item"
			mat-button
			routerLinkActive="active"
			[routerLink]="routePaths.invoices"
		>
			Invoices
		</a>
		<a
			class="list__item list-item"
			mat-button
			routerLinkActive="active"
			[routerLink]="routePaths.inspections"
		>
			Inspections
		</a>
		<a
			class="list__item list-item"
			mat-button
			routerLinkActive="active"
			[routerLink]="routePaths.periodic"
		>
			Periodic
		</a>
		<a
			class="list__item list-item"
			mat-button
			routerLinkActive="active"
			[routerLink]="routePaths.schedules"
		>
			Schedules
		</a>
		<a
			class="list__item list-item"
			mat-button
			routerLinkActive="active"
			[routerLink]="routePaths.dobNow"
		>
			Dob Now
		</a>
		<a
			class="list__item list-item"
			mat-button
			routerLinkActive="active"
			[routerLink]="routePaths.contacts"
		>
			Contacts
		</a>
		<button
			mat-icon-button
			type="button"
			(click)="logout()"
		>
			<mat-icon svgIcon="logout"></mat-icon>
		</button>
	</nav>
</mat-toolbar>
