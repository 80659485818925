import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { FooterComponent } from '../footer/footer.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { ToolbarComponent } from '../toolbar/toolbar.component';

/** App layout component.  */
@Component({
	selector: 'asecrmw-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		ToolbarComponent,
		CdkScrollable,
		BreadcrumbsComponent,
		RouterOutlet,
		FooterComponent,
	],
})
export class LayoutComponent { }
