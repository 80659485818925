import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconsService } from '@asecrmc/common/core/services/icons.service';
import { RouterOutlet } from '@angular/router';

/** Base app component. */
@Component({
	selector: 'asecrmw-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [RouterOutlet],
})
export class AppComponent {

	public constructor(iconService: IconsService) {
		iconService.registerIcons();
	}
}
