import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/** Custom icon data. */
type IconData = {

	/** Icon name. */
	name: string;

	/** Path to icon. */
	path: string;
};

/**
 * Service to register custom icons.
 */
@Injectable({
	providedIn: 'root',
})
export class IconsService {
	private readonly icons: IconData[] = [
		{
			name: 'logout',
			path: 'assets/icons/logout.svg',
		},
		{
			name: 'edit',
			path: 'assets/icons/edit.svg',
		},
		{
			name: 'add',
			path: 'assets/icons/add.svg',
		},
		{
			name: 'elevator',
			path: 'assets/icons/elevator.svg',
		},
		{
			name: 'test-type',
			path: 'assets/icons/test-type.svg',
		},
		{
			name: 'test-date',
			path: 'assets/icons/test-date.svg',
		},
		{
			name: 'payment-status',
			path: 'assets/icons/payment-status.svg',
		},
		{
			name: 'borough',
			path: 'assets/icons/borough.svg',
		},
		{
			name: 'stage',
			path: 'assets/icons/stage.svg',
		},
		{
			name: 'signing-status',
			path: 'assets/icons/signing-status.svg',
		},
		{
			name: 'inspector',
			path: 'assets/icons/inspector.svg',
		},
		{
			name: 'anchor',
			path: 'assets/icons/anchor.svg',
		},
	];

	public constructor(
		private readonly matIconRegistry: MatIconRegistry,
		private readonly domSanitizer: DomSanitizer,
	) {
	}

	/** Register icons. */
	public registerIcons(): void {
		this.icons.forEach(({ name, path }) => this.registerIcon(name, path));
	}

	private registerIcon(name: string, path: string): void {
		this.matIconRegistry.addSvgIcon(
			name,
			this.domSanitizer.bypassSecurityTrustResourceUrl(path),
		);
	}
}
